<template>
  <v-container fluid>
    <!-- Features Menu -->
    <CreditScoringFeaturesMenu />

    <!-- data-loading component for global loading state -->
    <data-loading :visible="dataLoading"></data-loading>

    <v-card>
      <v-card-title>
        <h2 class="headline mb-3">Credit Scoring Reports</h2>
        <v-spacer></v-spacer>
        <v-select
          v-model="selectedYear"
          :items="yearOptions"
          label="Select Year"
          class="mx-2"
          style="max-width: 150px"
          @change="fetchReportsByYear"
        ></v-select>
        <v-btn
          color="primary"
          :loading="loading"
          class="ml-2"
          @click="refreshReports"
        >
          <v-icon left>refresh</v-icon>
          Refresh
        </v-btn>
      </v-card-title>

      <v-card-text v-if="reports.length === 0 && !loading">
        <v-alert
          :value="true"
          type="info"
        >
          No reports found for the selected year. Please select a different year or create new reports.
        </v-alert>
      </v-card-text>

      <v-card-text v-if="reports.length > 0">
        <v-data-table
        :headers="reportHeaders"
        :items="reports"
        :loading="loading"
        class="elevation-1"
        >
        <template v-slot:items="props">
            <td>{{ props.item.month }}/{{ props.item.year }}</td>
            <td>{{ formatDate(props.item.generatedAt) }}</td>
            <td class="text-xs-center">{{ props.item.summary.totalLoans }}</td>
            <td class="text-xs-center">{{ props.item.summary.averageScore.toFixed(2) }}</td>
            <td class="text-xs-center">
                <v-chip :color="getScoreColor(props.item.summary.highestScore)" dark small>
                {{ props.item.summary.highestScore.toFixed(2) }}
                </v-chip>
            </td>
            <td class="text-xs-center">
                <v-chip :color="getScoreColor(props.item.summary.lowestScore)" dark small>
                {{ props.item.summary.lowestScore.toFixed(2) }}
                </v-chip>
            </td>
            <td class="text-xs-center">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-icon 
                            v-on="on" 
                            class="mr-2" 
                            @click="viewReportDetails(props.item)"
                        >visibility</v-icon>
                    </template>
                    <span>View</span>
                </v-tooltip>
                
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon 
                      v-on="on" 
                      class="mr-2" 
                      @click="exportReport(props.item)"
                      :disabled="props.item._id === exportingReportId"
                    >save_alt</v-icon>
                  </template>
                  <span>Export</span>
                </v-tooltip>
                
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-icon 
                            v-on="on" 
                            class="mr-2" 
                            @click="recalculateReport(props.item)"
                        >refresh</v-icon>
                    </template>
                    <span>Recalculate</span>
                </v-tooltip>
                
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-icon 
                            v-on="on" 
                            @click="confirmDeleteReport(props.item)"
                        >delete</v-icon>
                    </template>
                    <span>Delete</span>
                </v-tooltip>
            </td>
        </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <!-- Report Details Dialog -->
    <v-dialog v-model="detailsDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card v-if="currentReport">
        <v-toolbar>
          <v-btn icon @click="detailsDialog = false">
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>Report Details - {{ currentReport.month }}/{{ currentReport.year }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn :disabled="exporting" @click="exportReport()">
              <v-icon left>save_alt</v-icon>
              Export to Excel
            </v-btn>
            <v-btn text @click="detailsDialog = false">Close</v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-card-text class="pa-4">
          <!-- Key Metrics -->
          <div class="subheading font-weight-medium mb-3">Key Portfolio Metrics</div>
          <v-layout row wrap>
            <v-flex xs12 sm6 md3>
              <v-card class="ma-2" flat>
                <v-card-text class="pa-3" style="border: 1px solid rgba(0,0,0,0.12); border-radius: 4px;">
                  <div class="caption grey--text mb-1">TOTAL LOANS</div>
                  <div class="title font-weight-medium">{{ currentReport.summary.totalLoans }}</div>
                </v-card-text>
              </v-card>
            </v-flex>
            <v-flex xs12 sm6 md3>
              <v-card class="ma-2" flat>
                <v-card-text class="pa-3" style="border: 1px solid rgba(0,0,0,0.12); border-radius: 4px;">
                  <div class="caption grey--text mb-1">AVERAGE SCORE</div>
                  <div class="title font-weight-medium">{{ currentReport.summary.averageScore.toFixed(2) }}</div>
                </v-card-text>
              </v-card>
            </v-flex>
            <v-flex xs12 sm6 md3>
              <v-card class="ma-2" flat>
                <v-card-text class="pa-3" style="border: 1px solid rgba(0,0,0,0.12); border-radius: 4px;">
                  <div class="caption grey--text mb-1">HIGHEST SCORE</div>
                  <div class="title font-weight-medium">{{ currentReport.summary.highestScore.toFixed(2) }}</div>
                </v-card-text>
              </v-card>
            </v-flex>
            <v-flex xs12 sm6 md3>
              <v-card class="ma-2" flat>
                <v-card-text class="pa-3" style="border: 1px solid rgba(0,0,0,0.12); border-radius: 4px;">
                  <div class="caption grey--text mb-1">LOWEST SCORE</div>
                  <div class="title font-weight-medium">{{ currentReport.summary.lowestScore.toFixed(2) }}</div>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>

          <!-- Distribution Section -->
          <div class="mt-4 mb-3">
            <div class="subheading font-weight-medium">Score Distribution</div>
          </div>

          <v-card flat style="border: 1px solid rgba(0,0,0,0.12); border-radius: 4px;" class="mb-4">
            <v-card-text class="pa-4">
              <v-layout row wrap>
                <v-flex v-for="(value, key) in currentReport.summary.scoreDistribution" :key="key" xs12 sm6 md3>
                  <div class="text-xs-center">
                    <div class="title font-weight-medium grey--text text--darken-2">{{ value }}</div>
                    <div class="body-2">{{ key }}</div>
                  </div>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>

          <!-- Filters -->
          <div class="subheading font-weight-medium mt-4 mb-2">Data Filters</div>
          <v-layout row wrap>
            <v-flex xs12 sm6 md4>
              <v-text-field
                v-model="search"
                label="Search"
                prepend-icon="search"
                clearable
                class="mr-3"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 md4>
              <v-select
                v-model="scoreFilter"
                :items="detailScoreRanges"
                label="Score Range"
                clearable
              ></v-select>
            </v-flex>
          </v-layout>

          <!-- Data Table -->
          <v-data-table
            :headers="loanHeaders"
            :items="filteredLoans"
            :search="search"
            class="elevation-1"
          >
            <template v-slot:items="props">
              <td>{{ props.item.customerName }}</td>
              <td>{{ props.item.loanReferenceNumber }}</td>
              <td class="text-xs-right">{{ asMoney(props.item.originalLoanAmount) }}</td>
              <td class="text-xs-right">{{ asMoney(props.item.currentLoanBalance) }}</td>
              <td>{{ asMoney(props.item.ecl) }}</td>
              <td>{{ asMoney(props.item.interestAccrued) }}</td>
              <td>{{ props.item.stage }}</td>
              <td>{{ formatDate(props.item.loanOriginationDate) }}</td>
              <td>{{ formatDate(props.item.loanMaturityDate) }}</td>
              <td class="text-xs-center">
                <v-chip small outline>
                  {{ props.item.creditScore.toFixed(2) }}
                </v-chip>
              </td>
              <td class="text-xs-center">
                <v-btn small flat @click="showLoanDetails(props.item)">
                  Details
                </v-btn>
              </td>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Loan Details Dialog -->
    <v-dialog v-model="loanDialog" max-width="800px">
      <v-card v-if="selectedLoan">
        <v-card-title class="py-3 px-4" style="background-color: #f5f5f5;">
          <div class="d-flex justify-space-between align-center w-100">
            <span class="body-2 font-weight-medium">Applicant Details: {{ selectedLoan.customerName }}</span>
            <span class="caption ml-4">ID: #{{ selectedLoan.loanReferenceNumber || '0001' }}</span>
          </div>
          <v-divider class="my-2"></v-divider>
          <div class="mt-2">
            <span class="mr-4">
              <span class="caption grey--text">Credit Score</span>
              <span class="body-2 font-weight-medium d-block">{{ selectedLoan.creditScore.toFixed(2) }}</span>
            </span>
            <span class="mr-4">
              <span class="caption grey--text">Total Score</span> 
              <span class="body-2 font-weight-medium d-block">{{ selectedLoan.totalScore.toFixed(2) }}</span>
            </span>
            <span>
              <span class="caption grey--text">Max Possible</span>
              <span class="body-2 font-weight-medium d-block">{{ selectedLoan.maxPossibleScore.toFixed(2) }}</span>
            </span>
          </div>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="detailHeaders"
            :items="selectedLoan.scoreDetails"
            hide-actions
            class="elevation-1"
          >
            <template slot="items" slot-scope="props">
              <td>{{ props.item.riskFactorName }}</td>
              <td>{{ props.item.riskFactorValue }}</td>
              <td class="text-xs-right">{{ props.item.riskFactorScore }}</td>
              <td class="text-xs-right">
                {{ (props.item.subFactorScore * 100).toFixed(2) }}%
                <v-tooltip v-if="props.item.usedDefaultScore" bottom>
                  <v-icon small slot="activator">info</v-icon>
                  <span>Default score used</span>
                </v-tooltip>
              </td>
              <td class="text-xs-right">{{ props.item.finalScore.toFixed(2) }}</td>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions style="background-color: #f5f5f5;">
          <v-spacer></v-spacer>
          <v-btn flat @click="loanDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Confirm Delete Dialog -->
    <v-dialog v-model="deleteDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline error--text">
          Delete Report
        </v-card-title>
        <v-card-text>
          Are you sure you want to delete the report for {{ reportToDelete ? `${reportToDelete.month}/${reportToDelete.year}` : '' }}? This action cannot be undone.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" flat @click="deleteDialog = false">Cancel</v-btn>
          <v-btn color="error" @click="deleteReport">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Recalculate Dialog -->
    <v-dialog v-model="recalculateDialog" max-width="500px" persistent>
      <v-card>
        <v-card-title class="headline warning--text">
          Recalculate Report
        </v-card-title>
        <v-card-text>
          <p>Are you sure you want to recalculate the report for {{ reportToRecalculate ? `${reportToRecalculate.month}/${reportToRecalculate.year}` : '' }}? This will delete the current report and create a new one.</p>
          
          <!-- Show loading indicator -->
          <div class="text-center mt-4" v-if="recalculateLoading">
            <v-progress-circular
              indeterminate
              color="warning"
              size="24"
              class="mr-2"
            ></v-progress-circular>
            <span class="body-1">Processing... This may take a moment.</span>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn 
            color="grey darken-1" 
            flat 
            @click="recalculateDialog = false"
            :disabled="recalculateLoading"
          >
            Cancel
          </v-btn>
          <v-btn 
            color="warning" 
            @click="performRecalculation"
            :loading="recalculateLoading"
            :disabled="recalculateLoading"
          >
            Recalculate
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Snackbar for notifications -->
    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      :timeout="3000"
    >
      {{ snackbar.text }}
      <v-btn flat @click="snackbar.show = false">Close</v-btn>
    </v-snackbar>

    <error-dialog
      v-model="errorDialog.show"
      :title="errorDialog.title"
      :message="errorDialog.message"
      :details="errorDialog.details"
      :type="errorDialog.type"
      :suggestion="errorDialog.suggestion"
      :show-retry-button="errorDialog.showRetryButton"
      :show-action-button="errorDialog.showActionButton"
      :action-button-text="errorDialog.actionButtonText"
      :action-button-color="errorDialog.actionButtonColor"
      @retry="handleErrorRetry"
      @action="handleErrorAction"
    />
  </v-container>
</template>

<script>
import creditScoringService from '@/services/creditScoringService';
import CreditScoringFeaturesMenu from '@/components/CreditScoringFeaturesMenu.vue';
import ErrorDialog from '@/components/ErrorDialogComponent.vue';
import moment from 'moment';

export default {
  name: 'ViewReports',
  
  components: {
    CreditScoringFeaturesMenu,
    ErrorDialog
  },

  data() {
    return {
      loading: false,
      exporting: false,
      exportingReportId: null,
      reports: [],
      selectedYear: new Date().getFullYear().toString(),
      yearOptions: this.generateYearOptions(),
      detailsDialog: false,
      loanDialog: false,
      deleteDialog: false,
      recalculateDialog: false,
      recalculateLoading: false,
      dataLoading: false,
      currentReport: null,
      selectedLoan: null,
      reportToDelete: null,
      reportToRecalculate: null,
      search: '',
      scoreFilter: null,
      snackbar: {
        show: false,
        text: '',
        color: 'info'
      },

      reportHeaders: [
        { text: 'Period', value: 'month' },
        { text: 'Created Date', value: 'generatedAt' },
        { text: 'Total Loans', value: 'summary.totalLoans', align: 'center' },
        { text: 'Average Score', value: 'summary.averageScore', align: 'center' },
        { text: 'Highest Score', value: 'summary.highestScore', align: 'center' },
        { text: 'Lowest Score', value: 'summary.lowestScore', align: 'center' },
        { text: 'Actions', value: 'actions', sortable: false, align: 'center' }
      ],

      loanHeaders: [
        { text: 'Customer Name', value: 'customerName' },
        { text: 'Loan Reference', value: 'loanReferenceNumber' },
        { text: 'Original Amount', value: 'originalLoanAmount', align: 'right' },
        { text: 'Current Balance', value: 'currentLoanBalance', align: 'right' },
        { text: 'ECL', value: 'ecl', align: 'right' },
        { text: 'Interest Accrued', value: 'interestAccrued', align: 'right' },
        { text: 'Stage', value: 'stage' },
        { text: 'Origination Date', value: 'loanOriginationDate' },
        { text: 'Maturity Date', value: 'loanMaturityDate' },
        { text: 'Credit Score', value: 'creditScore', align: 'center' },
        { text: 'Actions', value: 'actions', sortable: false, align: 'center' }
      ],

      detailHeaders: [
        { text: 'Risk Factor', value: 'riskFactorName' },
        { text: 'Value', value: 'riskFactorValue' },
        { text: 'Factor Score', value: 'riskFactorScore', align: 'right' },
        { text: 'Sub-Factor Score', value: 'subFactorScore', align: 'right' },
        { text: 'Final Score', value: 'finalScore', align: 'right' }
      ],

      errorDialog: {
        show: false,
        title: 'Error',
        message: '',
        details: '',
        type: 'error',
        suggestion: '',
        showRetryButton: false,
        showActionButton: false,
        actionButtonText: 'Action',
        actionButtonColor: 'primary',
        retryCallback: null,
        actionCallback: null
      },
    }
  },
  
  watch: {
    '$route': {
      handler(newRoute) {
        // Check if there's a reportId in the query parameters
        const reportId = newRoute.query.reportId;
        if (reportId) {
          // Find the report in the loaded reports
          const reportToOpen = this.reports.find(report => report._id === reportId);
          
          if (reportToOpen) {
            // Open the report details
            this.viewReportDetails(reportToOpen);
          } else {
            // If not found in the current year, try to load the report directly
            this.openReportById(reportId);
          }
          
          // Clear the reportId from the URL to avoid reopening on refresh
          const newUrl = window.location.pathname;
          window.history.replaceState({}, document.title, newUrl);
        }
      },
      immediate: true
    }
  },

  computed: {
    filteredLoans() {
      if (!this.currentReport || !this.currentReport.loans) return [];

      let filtered = [...this.currentReport.loans];
      console.log("filtered ==> ", filtered);

      if (this.scoreFilter) {
        // Get the specific filter key that was selected
        const selectedFilter = this.scoreFilter;
        
        // Filter loans based on the riskRating combination matching the selected filter
        filtered = filtered.filter(loan => {
          // Check if the loan has the required properties
          if (loan.riskRating && loan.riskRating.categoryName && loan.riskRating.definition) {
            const riskKey = `${loan.riskRating.categoryName}: ${loan.riskRating.definition}`;
            return riskKey === selectedFilter;
          }
          return false;
        });
      }

      return filtered;
    }
  },

  methods: {
    async initialize() {
      await this.fetchReportsByYear();
      
      // Check if there's a reportId in the query parameters
      const reportId = this.$route.query.reportId;
      if (reportId) {
        // Find the report in the loaded reports
        const reportToOpen = this.reports.find(report => report._id === reportId);
        
        if (reportToOpen) {
          // Open the report details
          this.viewReportDetails(reportToOpen);
        } else {
          // If not found in the current year, try to load the report directly
          this.openReportById(reportId);
        }
        
        // Clear the reportId from the URL to avoid reopening on refresh
        // Use replaceState to modify URL without triggering navigation
        const newUrl = window.location.pathname;
        window.history.replaceState({}, document.title, newUrl);
      }
    },

    async openReportById(reportId) {
      this.loading = true;
      try {
        const response = await creditScoringService.fetchReportById(reportId);
        if (response.result) {
          // Update the selected year to match the report's year
          this.selectedYear = response.value.year;
          
          // Process the report and show it
          this.currentReport = this.processReportData(response.value);
          
          // Generate score ranges for the detail view
          this.generateDetailScoreRanges();
          
          // Open the dialog
          this.detailsDialog = true;
          
          // Refresh the reports list for the correct year
          await this.fetchReportsByYear();
        } else {
          this.showErrorDialog({
            title: 'Report Not Found',
            message: 'Failed to load the requested report',
            details: response.error ? response.error.message : 'Unknown error',
            type: 'error'
          });
        }
      } catch (error) {
        this.showErrorDialog({
          title: 'Report Loading Error',
          message: 'An error occurred while loading the report',
          details: error.message,
          type: 'error'
        });
        console.error('Error:', error);
      } finally {
        this.loading = false;
      }
    },

    generateYearOptions() {
      // Generate a list of years from 2020 to current year
      const currentYear = new Date().getFullYear()
      let years = []
      for (let year = currentYear; year >= 2020; year--) {
        years.push(year.toString())
      }
      return years
    },

    async fetchReportsByYear() {
      if (!this.selectedYear) return;

      this.loading = true;
      try {
        const response = await creditScoringService.fetchReportsByYear(this.selectedYear);
        if (response.result) {
          this.reports = this.processReportData(response.value);
          console.log("this.reports ==> ", this.reports);
        } else {
          this.showErrorDialog({
            title: 'Data Loading Error',
            message: `Failed to load reports for ${this.selectedYear}`,
            details: response.error ? response.error.message : 'Unknown error',
            type: 'error',
            showRetryButton: true,
            retryCallback: this.fetchReportsByYear
          });
        }
      } catch (error) {
        this.showErrorDialog({
          title: 'Connection Error',
          message: 'Failed to connect to the server',
          details: error.message,
          type: 'error',
          showRetryButton: true,
          retryCallback: this.fetchReportsByYear
        });
        console.error('Error:', error);
      } finally {
        this.loading = false;
      }
    },

    // Generate score ranges dynamically from the currentReport
    generateDetailScoreRanges() {
      // Clear existing ranges
      this.detailScoreRanges = [];
      
      // Generate new ranges based on the score distribution in the currentReport
      if (this.currentReport && this.currentReport.summary && this.currentReport.summary.scoreDistribution) {
        
        Object.keys(this.currentReport.summary.scoreDistribution).forEach(key => {
          const count = this.currentReport.summary.scoreDistribution[key];
          this.detailScoreRanges.push({
            text: `${key} (${count})`,
            value: key
          });
        });
        
        // Sort ranges by key for better organization
        this.detailScoreRanges.sort((a, b) => a.value.localeCompare(b.value));
      }
    },

    async viewReportDetails(report) {
      this.loading = true;
      try {
        // Fetch the full report details including loans
        const response = await creditScoringService.fetchReportById(report._id);
        if (response.result) {
          this.currentReport = this.processReportData(response.value);
          
          // Generate score ranges for the detail view
          this.generateDetailScoreRanges();
          
          // Open the dialog
          this.detailsDialog = true;
        } else {
          this.showErrorDialog({
            title: 'Report Details Error',
            message: 'Failed to load the report details',
            details: response.error ? response.error.message : 'Unknown error',
            type: 'error'
          });
        }
      } catch (error) {
        this.showErrorDialog({
          title: 'Report Details Error',
          message: 'An error occurred while loading the report details',
          details: error.message,
          type: 'error'
        });
        console.error('Error:', error);
      } finally {
        this.loading = false;
      }
    },

    // Process a single report data - handle Decimal128 values in loans
    processReportData(report) {
      // Make a copy of the report to avoid mutating the original
      const processedReport = JSON.parse(JSON.stringify(report))
      
      // Process summary numeric fields
      if (processedReport.summary) {
        processedReport.summary.averageScore = this.convertDecimal(processedReport.summary.averageScore)
        processedReport.summary.highestScore = this.convertDecimal(processedReport.summary.highestScore)
        processedReport.summary.lowestScore = this.convertDecimal(processedReport.summary.lowestScore)
      }
      
      // Process loans
      if (processedReport.loans && Array.isArray(processedReport.loans)) {
        processedReport.loans = processedReport.loans.map(loan => {
          loan.originalLoanAmount = this.convertDecimal(loan.originalLoanAmount)
          loan.currentLoanBalance = this.convertDecimal(loan.currentLoanBalance)
          loan.creditScore = this.convertDecimal(loan.creditScore)
          loan.ecl = this.convertDecimal(loan.ecl)
          loan.interestAccrued = this.convertDecimal(loan.interestAccrued)
          
          // Process score details
          if (loan.scoreDetails && Array.isArray(loan.scoreDetails)) {
            loan.scoreDetails = loan.scoreDetails.map(detail => {
              detail.riskFactorScore = this.convertDecimal(detail.riskFactorScore)
              detail.subFactorScore = this.convertDecimal(detail.subFactorScore)
              detail.finalScore = this.convertDecimal(detail.finalScore)
              return detail
            })
          }
          
          return loan
        })
      }
      
      return processedReport
    },

    // Helper function to convert Decimal128 values
    convertDecimal(value) {
      if (!value) return 0
      
      // Handle Decimal128 format: {"$numberDecimal": "25000"}
      if (typeof value === 'object' && value !== null && value.$numberDecimal) {
        return Number(value.$numberDecimal)
      }
      
      // Handle other object types with numeric values
      if (typeof value === 'object' && value !== null) {
        return Number(Object.values(value)[0] || 0)
      }
      
      // Already a number or can be converted to one
      return Number(value) || 0
    },

    async refreshReports() {
      await this.fetchReportsByYear()
      this.showNotification('Reports refreshed', 'success')
    },

    showLoanDetails(loan) {
      this.selectedLoan = loan
      this.loanDialog = true
    },

    confirmDeleteReport(report) {
      this.reportToDelete = report
      this.deleteDialog = true
    },

    async deleteReport() {
      if (!this.reportToDelete) {
        this.deleteDialog = false;
        return;
      }

      this.loading = true;
      try {
        const response = await creditScoringService.deleteReport(this.reportToDelete._id);
        
        if (response.result) {
          // Remove from the list
          this.reports = this.reports.filter(r => r._id !== this.reportToDelete._id);
          this.showNotification('Report deleted successfully', 'success');
        } else {
          this.showErrorDialog({
            title: 'Delete Failed',
            message: 'Failed to delete the report',
            details: response.error ? response.error.message : 'Unknown error',
            type: 'error'
          });
        }
      } catch (error) {
        this.showErrorDialog({
          title: 'Delete Error',
          message: 'An error occurred while deleting the report',
          details: error.message,
          type: 'error'
        });
        console.error('Error:', error);
      } finally {
        this.loading = false;
        this.deleteDialog = false;
        this.reportToDelete = null;
      }
    },

    recalculateReport(report) {
      this.reportToRecalculate = report
      this.recalculateDialog = true
    },

    async performRecalculation() {
      if (!this.reportToRecalculate) {
        this.recalculateDialog = false;
        return;
      }

      // Set both loading indicators to true
      this.recalculateLoading = true;
      this.dataLoading = true;
      
      try {
        // First, delete the existing report
        const deleteResponse = await creditScoringService.deleteReport(this.reportToRecalculate._id);
        
        if (!deleteResponse.result) {
          this.showErrorDialog({
            title: 'Recalculation Failed',
            message: 'Failed to delete the existing report',
            details: deleteResponse.error ? deleteResponse.error.message : 'Unknown error',
            type: 'error'
          });
          return;
        }

        // Then, find the upload ID for this report
        const uploadId = this.reportToRecalculate.uploadId;
        
        if (!uploadId) {
          this.showErrorDialog({
            title: 'Recalculation Failed',
            message: 'Upload ID not found for this report',
            details: 'The original data source for this report could not be identified.',
            type: 'error'
          });
          return;
        }

        // Calculate new scores with the same upload
        const response = await creditScoringService.calculateScores(uploadId);
        
        if (response.result) {
          // Refresh the reports list
          await this.fetchReportsByYear();
          this.showNotification('Report recalculated successfully', 'success');
          
          // Close the dialog after successful recalculation
          this.recalculateDialog = false;
        } else {
          // Check for 409 error (existing report)
          if (response.status === 409 && response.data && response.data.existingReportId) {
            this.showErrorDialog({
              title: 'Recalculation Failed',
              message: 'A report already exists for this month and year',
              details: 'The report could not be recalculated because another report was created for the same period.',
              type: 'warning'
            });
          } else {
            this.showErrorDialog({
              title: 'Recalculation Failed',
              message: 'Failed to recalculate the report',
              details: response.error ? response.error.message : 'Unknown error',
              type: 'error'
            });
          }
        }
      } catch (error) {
        this.showErrorDialog({
          title: 'Recalculation Error',
          message: 'An error occurred during recalculation',
          details: error.message,
          type: 'error'
        });
        console.error('Error:', error);
      } finally {
        // Reset loading indicators
        this.recalculateLoading = false;
        this.dataLoading = false;
        this.reportToRecalculate = null;
      }
    },
    
    async exportReport(report = null) {
      // If no report is provided, use the current report (from detail view)
      const reportToExport = report || this.currentReport;
      
      // Validate we have a report to export
      if (!reportToExport) {
        this.showNotification('No report selected for export', 'error');
        return;
      }
      
      // Set appropriate loading flags based on context
      const isFromDetailView = !report;
      if (isFromDetailView) {
        this.exporting = true;
      } else {
        this.exportingReportId = reportToExport._id;
      }
      
      // Always show global loading
      this.dataLoading = true;
      
      try {
        await this.performExport(reportToExport._id);
      } finally {
        // Reset loading indicators
        if (isFromDetailView) {
          this.exporting = false;
        } else {
          this.exportingReportId = null;
        }
        this.dataLoading = false;
      }
    },

    // Common export logic
    async performExport(reportId) {
      try {
        const response = await creditScoringService.exportReportToExcel(reportId);
        
        if (response.result) {
          // Create a download link for the blob
          const url = window.URL.createObjectURL(new Blob([response.value]));
          const link = document.createElement('a');
          link.href = url;
          
          // Get month/year from the report for filename
          let month = 'unknown';
          let year = 'unknown';
          
          // Find the report by ID
          const report = this.reports.find(r => r._id === reportId) || this.currentReport;
          if (report) {
            month = report.month;
            year = report.year;
          }
          
          link.setAttribute('download', `credit-report-${month}-${year}.xlsx`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          
          this.showNotification('Report exported successfully', 'success');
        } else {
          this.showErrorDialog({
            title: 'Export Failed',
            message: 'Failed to export the report',
            details: response.error ? response.error.message : 'Unknown error',
            type: 'error',
            showRetryButton: true,
            retryCallback: () => this.performExport(reportId)
          });
        }
      } catch (error) {
        this.showErrorDialog({
          title: 'Export Error',
          message: 'An error occurred while exporting the report',
          details: error.message,
          type: 'error',
          showRetryButton: true,
          retryCallback: () => this.performExport(reportId)
        });
        console.error('Export error:', error);
      }
    },

    formatDate(date) {
      return date ? moment(date).format('YYYY-MM-DD') : 'N/A'
    },

    getScoreColor(score) {
      const scoreValue = this.convertDecimal(score)
      if (scoreValue >= 800) return 'success'
      if (scoreValue >= 700) return 'info'
      if (scoreValue >= 600) return 'warning'
      return 'error'
    },

    showNotification(text, color = 'info') {
      this.snackbar = {
        show: true,
        text,
        color
      }
    },

    showErrorDialog(options) {
      // Default values for options
      const errorTitle = options.title || 'Error';
      const errorMessage = options.message || 'An error occurred';
      const errorDetails = options.details || '';
      const errorType = options.type || 'error';
      const suggestion = options.suggestion || '';
      const showRetryButton = options.showRetryButton || false;
      
      // Make sure we have a valid action function if we're showing the action button
      const hasValidAction = typeof options.actionCallback === 'function';
      const showActionButton = options.showActionButton && hasValidAction;
      const actionButtonText = options.actionButtonText || 'Action';
      const actionButtonColor = options.actionButtonColor || 'primary';
      
      // Set error dialog properties
      this.errorDialog = {
        show: true,
        title: errorTitle,
        message: errorMessage,
        details: errorDetails,
        type: errorType,
        suggestion: suggestion,
        showRetryButton: showRetryButton,
        showActionButton: showActionButton,
        actionButtonText: actionButtonText,
        actionButtonColor: actionButtonColor,
        retryCallback: options.retryCallback || null,
        actionCallback: hasValidAction ? options.actionCallback : null
      };
      
      // Warn if action button is requested but no valid action provided
      if (options.showActionButton && !hasValidAction) {
        console.warn('showActionButton is true but no valid action handler was provided');
      }
    },

    handleErrorRetry() {
      if (typeof this.errorDialog.retryCallback === 'function') {
        this.errorDialog.retryCallback();
      }
    },

    handleErrorAction() {
      if (typeof this.errorDialog.actionCallback === 'function') {
        this.errorDialog.actionCallback();
      }
    },
  },

  created() {
    this.initialize()
  }
}
</script>

<style scoped>
.v-chip {
  font-weight: bold;
}

.headline {
  font-weight: 500;
}

.display-1 {
  font-size: 2.5rem !important;
}

.v-data-table td {
  height: 48px;
}

.rotating {
  animation: rotate 1s infinite linear;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>